import React, { useEffect, useState } from "react"
import axios from "axios"

import { trackEvent } from "../helpers"
import styles from "./bis.module.scss"


const BIS = ({ variant, style, successProp, setSuccessProp, customStyles }) => {
  const [input, setInput] = useState("")
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    setSuccess(successProp)
  }, [successProp])

  const submitEmail = e => {
    e.preventDefault()

    // window.BIS.create(email, variant.variant_id, style.product_id)
    //   .then(response => {
    //     if (response.status === "OK" || response.errors.email[0].includes("Thank you")) {
    //       setSuccess(true)
    //       trackEvent("PDP", "Joined", "Waitlist")
    //     } else
    //       setErrorMessage(response.errors.email[0])
    //   })

    axios.get(`https://app.backinstock.org/stock_notification/create.json?shop=tamara-dev.myshopify.com&${(input.includes("@")) ? `notification[email]=${input}&` : `notification[phone_number]=%2B1${input.replace(/(-|\(|\)|\s)/g,"")}&`}notification[product_no]=${style.product_id}&notification[quantity_required]=1&notification[accepts_marketing]=true&notification[customer_utc_offset]=${new Date().getTimezoneOffset() * 60}&variant[variant_no]=${variant.variant_id}`)
      .then(({ data }) => {
        console.log(data)
        if (data.status === "OK" || (data.errors.email && data.errors.email[0].includes("Thank you")) || (data.errors.base && data.errors.base[0].includes("Thank you"))) {
          setSuccess(true)
          if (setSuccessProp)
            setSuccessProp(true)
          trackEvent("PDP", "Joined", "Waitlist")
        } else if (data.errors.email)
          setErrorMessage("The email address you entered is invalid. Please try again.")
        else if (data.errors.phone_number)
          setErrorMessage("The phone number you entered is invalid. Please try again.")
        else if (data.errors.base)
          setErrorMessage("Please enter an email address or phone number first.")
        else
          setErrorMessage("Something went wrong. Try again.")
      })
  }

  return (
    <div className={`${styles.bisContainer} ${customStyles.bisContainer}`}>
      {success ?
        <p>Thank you. We’ll notify you when this item is back in stock.</p>
        :
        <>
          <p>Enter your email or US phone number to be notified when {(style.productType === "Footwear") ? `size ${variant.size}` : `this item`} is back in stock.</p>
          <form
            className={`${styles.bisForm} ${customStyles.bisForm}`}
            onSubmit={submitEmail}
            noValidate
          >
            <input
              type="text"
              placeholder="Email or phone number"
              className={(errorMessage) ? `${styles.bisEmailError} ${customStyles.bisEmailError}` : `${styles.bisEmail} ${customStyles.bisEmail}`}
              value={input}
              onChange={e => {
                setInput(e.target.value)
                setErrorMessage(null)
              }}
              required
            />
            <input
              type="submit"
              value="Notify Me"
              className={(input !== "") ? `${styles.bisSubmitActive} ${customStyles.bisSubmitActive}` : `${styles.bisSubmit} ${customStyles.bisSubmit}`}
            />
          </form>
          {errorMessage &&
          <p className={styles.bisError}>
            {errorMessage}
          </p>
          }
        </>
      }
    </div>
  )
}

export default BIS
