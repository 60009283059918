import swiperStyles from "./swiper.module.scss"


export const getDefaultParams = (data, customStyles) => {
  const {
    navigation,
    pagination,
    rebuildOnUpdate,
    loop,
  } = data
  
  const styles = {...swiperStyles, ...customStyles}

  // TODO: Fix lazy loading of images on duplicate looped slides
  // Examples: mobile UGC, Promos
  return {
    containerClass: `${styles.swiperContainer}`,
    WrapperEl: "ol",
    threshold: 10,
    watchOverflow: true,
    preloadImage: true,
    updateOnImagesReady: true,
    navigation: navigation ?
      {
        // prevEl: `${styles.swiperButtonPrev}`,
        // nextEl: `${styles.swiperButtonNext}`,
        disabledClass: `${styles.swiperButtonDisabled}`,
      } : {},
    // renderPrevButton: () => <button className={`${styles.swiperButtonPrev} swiper-navigation-prev`}></button>,
    // renderNextButton: () => <button className={`${styles.swiperButtonNext} swiper-navigation-next`}></button>,
    pagination: pagination ?
      {
        el: `.${styles.swiperPagination} .swiper-pagination`,
        // bulletElement: "li",
        bulletClass: `${styles.swiperPaginationBullet} swiper-pagination-bullet`,
        bulletActiveClass: `${styles.swiperPaginationBulletActive} swiper-pagination-bullet-active`,
        clickable: true,
      } : {},
    // renderPagination: () => <ol className={`${styles.swiperPagination}`}></ol>,
    // shouldSwiperUpdate: true,
    rebuildOnUpdate: rebuildOnUpdate,
    loop: loop,
  }
}
