import React, { useState, useEffect, useContext, useRef } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import { Helmet } from "react-helmet"
import qs from "qs"
import { sha256 } from "js-sha256"
import aa from "search-insights"

import { AppContext } from "../components/Context"
import SEO from "../components/SEO"
import PdpImages from "../components/PdpImages"
import PdpImagesVideoFirst from "../components/PdpImagesVideoFirst"
import Price from "../components/Price"
import Swatches from "../components/Swatches"
import BIS from "../components/BIS"
import FavoriteIcon from "../components/FavoriteIcon"
// import PdpYMAL from "../components/PdpYMAL"
import Heading from "../components/Heading"
import RichText from "../components/RichText"
import { saveProductToRV } from "../components/RecentlyViewed"

import { convertMmToInches, trackEvent, cookie } from "../helpers"
import cart from "../helpers/cart"

import styles from "./product.module.scss"
import "./product.scss"
import overlayStyles from "../components/overlay.module.scss"

const Product = ({ data, location }) => {
  const appState = useContext(AppContext)

  aa("init", {
    appId: "WN0K2FPQPE",
    apiKey: "8e5ab94e5bb026fd40906a5712e6d546"
  })

  const {
    productStyle,
    contentfulTextBlock,
  } = data

  const {
    title,
    skus,
    product_id,
    description,
    relatedStyles,
    productType,
    // recommendations,
  } = productStyle

  // const [urgencyAmount, setUrgencyAmount] = useState(null)

  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true })
  const queryColor = parsedQuery.color || ""
  const querySize = parsedQuery.size || ""

  // Function to convert US sizes to equivalent EU sizes if necessary
  const normalizeSize = (size) => {
    // Convert size to number if it's a string representation of a number
    const numericSize = Number(size);
    // Check if the size is a US size (14 or under) and convert to EU size by adding 30
    return numericSize && numericSize <= 13 ? numericSize + 30 : numericSize;
  };

  const defaultStyle = productStyle
  const defaultSku = skus.find(sku => sku.color.toLowerCase() === queryColor.toLowerCase()) || skus.find(sku => sku.images.swatch) || skus[0]

  let defaultVariant = ""
  // Normalize querySize and appState.selectedSize
  const normalizedQuerySize = normalizeSize(querySize);
  const normalizedAppStateSize = normalizeSize(appState.selectedSize);

  if (normalizedQuerySize) {
    // If there's a normalizedQuerySize, try to find a matching variant
    defaultVariant = defaultSku.variants.find(v => normalizeSize(v.size) === normalizedQuerySize);
  }

  if (!defaultVariant && normalizedAppStateSize) {
    // If no variant was found with normalizedQuerySize and normalizedAppStateSize is set, find a matching variant
    defaultVariant = defaultSku.variants.find(v => normalizeSize(v.size) === normalizedAppStateSize);
  }

  if (!defaultVariant) {
    // If no variant was found with either normalizedQuerySize or normalizedAppStateSize, default to the smallest size variant
    defaultVariant = defaultSku.variants.sort((a, b) => Number(a.size) - Number(b.size))[0];
  }

  const [selectedStyle, setSelectedStyle] = useState(defaultStyle)
  const [selectedSku, setSelectedSku] = useState(defaultSku)
  const [selectedVariant, setSelectedVariant] = useState(defaultVariant)
  const [selectedSize, setSelectedSize] = useState(defaultVariant)

  const [quantity, setQuantity] = useState(selectedSku.variants.reduce((acc, cur) => (acc += cur.quantity), 0))

  const [sizeSelectError, setSizeSelectError] = useState(null)

  const [bisSuccess, setBisSuccess] = useState(false)

  const [allStyles, setAllStyles] = useState([])
  const [allSkus, setAllSkus] = useState([])

  const [isClient, setClient] = useState(false)
  const key = isClient ? "client" : "server"
  
  useEffect(() => {
    setClient(true)
  }, [])

  // Remove half sizes from Pillow Top, Setter size select
  // Remove 41.5 and 42 sizes from Opanca size select
  useEffect(() => {
    if (["pillow-talk-nappa-flats", "setter-patent-flats", "setter-velvet-flats"].includes(selectedStyle.handle))
      selectedSku.variants = selectedSku.variants.filter(v => parseFloat(v.size) % 1 === 0)
    if (["all-clear-vitello-sandals", "red-alert-40-vitello-sandals", "red-alert-100-vitello-sandals"].includes(selectedStyle.handle))
      selectedSku.variants = selectedSku.variants.filter(v => parseFloat(v.size) !== 41.5 && parseFloat(v.size) !== 42)
  }, [selectedStyle, selectedSku])

  // Combine skus of related styles with skus of this style
  useEffect(() => {
    let updatedStyles = []
    updatedStyles.push(productStyle)
    // relatedStyles.forEach(style => {
    //   updatedStyles.push(style)
    // })
    setAllStyles(updatedStyles)

    let updatedSkus = []
    skus.forEach(sku => {
      updatedSkus.push(sku)
    })
    // relatedStyles.forEach(style => {
    //   style.skus.forEach(sku => {
    //     updatedSkus.push(sku)
    //   })
    // })
    setAllSkus(updatedSkus)
  }, [productStyle, skus, relatedStyles])

  // Save current product to user"s Recently Viewed
  useEffect(() => {
    saveProductToRV(productStyle)
  }, [productStyle])

  const [quantityUpdated, setQuantityUpdated] = useState(false)

  // Get up-to-date inventory
  useEffect(() => {
    // const idQuery = relatedStyles.reduce((acc, cur) => (
    //   `${acc} OR id:${cur.product_id}`
    // ), `id:${product_id}`)
    const idQuery = `id:${product_id}`
    // get inventory from Shopify
    axios({
      url: "https://tamara-dev.myshopify.com/api/2020-04/graphql.json",
      method: "post",
      headers: {
        "X-Shopify-Storefront-Access-Token": "14ddd2b4c87fb6bc9c77e95cf3bed2c5",
      },
      data: {
        query: `
          query InventoryForSku {
            products(first: 10, query: "${idQuery}") {
              edges {
                node {
                  handle
                  variants(first: 100) {
                    edges {
                      node {
                        id
                        sku
                        quantityAvailable
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      },
    })
    .then(result => {
      // Flatten variants from response for easier traversal
      let responseVariants = []
      result.data.data.products.edges.forEach(product => {
        product.node.variants.edges.forEach(variant => {
          responseVariants.push(variant.node)
        })
      })
      // Add inventory to skus
      allSkus.forEach(sku => {
        sku.variants.forEach(variant => {
          const upc = variant.upc
          const matchingVariant = responseVariants.find(v => upc === v.sku)
          if (matchingVariant)
            variant.quantity = matchingVariant.quantityAvailable
          if (variant.variant_id === selectedVariant.variant_id)
            setSelectedVariant(variant)
        })
      })
      setQuantityUpdated(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSkus])

  const updateSelectedSku = sku => {
    updateSelectedStyle(sku)
    setSelectedSku(sku)
    setSelectedVariant(sku.variants.find(v => v.size === selectedVariant.size))
    trackEvent("PDP", "Click", "Swatch")
    window.scrollTo(0,0)
  }

  const [images, setImages] = useState((selectedSku.images.pdp2.length) ? selectedSku.images.pdp2 : selectedSku.images.pdp)
  // const [videos, setVideos] = useState(selectedSku.videos.pdp)

  useEffect(() => {
    setImages((selectedSku.images.pdp2.length) ? selectedSku.images.pdp2 : selectedSku.images.pdp)
    // setVideos(selectedSku.videos.pdp)

    setQuantity(selectedSku.variants.reduce((acc, cur) => (acc += cur.quantity), 0))
  }, [selectedSku])

  const updateSelectedStyle = sku => {
    // const style = relatedStyles.find(style => style.skus.find(s => s.id === sku.id)) || defaultStyle
    const style = defaultStyle
    setSelectedStyle(style)
    if (typeof window !== "undefined") {
      const url = `/products/${style.handle}?color=${sku.color}${(parsedQuery.origin) ? `&origin=${parsedQuery.origin}` : ""}`
      window.history.replaceState({}, document.title, url)
    }
  }

  const [showSizeSelect, setShowSizeSelect] = useState(false)

  const handleSizeClick = variant => {
    setSelectedVariant(variant)
    setSelectedSize(variant.size)
    appState.set({
      selectedSize: variant.size
    })
    setSizeSelectError(null)
    setBisSuccess(false)
    setShowSizeSelect(false)
    if (typeof window !== "undefined")
      localStorage.setItem("selectedSize", variant.size)
    if (variant.quantity > 0)
      trackEvent("PDP", "Click", "In-stock Size")
    else if (variant.quantity === 0)
      trackEvent("PDP", "Click", "Out-of-stock Size")
  }

  // Close size select if clicked out of
  const handleOutsideSizeClick = e => {
    const path = e.composedPath()
    if (setShowSizeSelect && path) {
      const clickedOnSizeSelect = path.some(el => {
        return el && el.className && el.className.includes("size")
      })
      if (!clickedOnSizeSelect)
        setShowSizeSelect(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined")
      document.addEventListener("click", handleOutsideSizeClick)
    return () =>
      document.removeEventListener("click", handleOutsideSizeClick)
  })

  const handleGiftCardAmountChange = e => {
    const sku = allSkus.find(s => s.color === e.target.value)
    updateSelectedSku(sku)
  }

  const handleLegSizeChange = e => {
    const style = allStyles.find(s => s.title[s.title.length-1] === e.target.value)
    const sku = style.skus[0]
    updateSelectedSku(sku)
  }

  const addToCart = () => {
    if (appState.selectedSize !== null || selectedStyle.productType === "Handbag" || selectedStyle.productType === "Gift Card" || selectedStyle.title === "Shoe Cube Duo") {
      const newItem = {
        productId: selectedStyle.product_id,
        variantId: selectedVariant.variant_id,
        quantity: 1,
        maxQuantity: selectedVariant.quantity,
        image: selectedSku.images.plp2.sort()[0],
        title: selectedStyle.title,
        price: parseInt(selectedVariant.price, 10),
        compareAtPrice: (selectedVariant.compareAtPrice) ?
          parseInt(selectedVariant.compareAtPrice, 10)
          : null,
        // intlPrices: selectedVariant.intlPrices,
        url: `${selectedStyle.url}?color=${selectedSku.color}`,
        color: selectedSku.color,
        size: selectedVariant.size,
        dateExpected: selectedVariant.dateExpected,
        sku: selectedSku.sku,
        upc: selectedVariant.upc,
        saleType: (selectedStyle.tags.includes("EXCHANGE_ONLY")) ? "exchangeOnly" :
          (selectedStyle.tags.includes("FINAL_SALE")) ? "finalSale" : null,
      }

      // Check if item is already added to cart
      const cartItem = cart.getCart().find(item => item.variantId === newItem.variantId)
      if (cartItem) {
        // Check if cart already has max quantity available
        if (cartItem.quantity === newItem.maxQuantity) {
          // Set error and return
          setSizeSelectError("All available items in this size are in your cart")
          return
        } else
          // Otherwise, increment quantity in cart by 1
          cart.incrementQuantity(newItem, 1)
      } else
        // Otherwise, add item to cart
        cart.addItem(newItem)

      appState.set({
        cartCount: cart.getCartCount(),
        miniCartOpen: true,
      })

      addToCartAnalytics()
    }
  }

  const addToCartAnalytics = () => {
    // GA
    trackEvent("PDP", "Click", "Add to Cart")

    if (window.hasOwnProperty("gtag"))
      window.gtag("event", "add_to_cart", {
        "items": [{
          "id": `${selectedStyle.product_id}`,
          "name": selectedStyle.title,
          "brand": "Tamara Mellon",
          "variant": `${selectedSku.color.toUpperCase()} / ${selectedVariant.size} / ${selectedStyle.heel_height}`,
          "quantity": 1,
          "price": `${selectedVariant.price}.00`,
          "category": productType,
        }],
      })

    const fbEventId = Math.floor(Math.random() * 1000000) + ""

    // Facebook
    if (window.hasOwnProperty("fbq"))
      window.fbq("track", "AddToCart",
        {
          content_type: "product",
          content_ids: [`${selectedStyle.product_id}`],
          value: selectedVariant.price,
          currency: "USD",
        },
        {
          eventID: fbEventId,
        },
      )

    const payload = [
      {
        "event_name": "AddToCart",
        "event_time": Math.floor(new Date().getTime() / 1000),
        "action_source": "website",
        "event_source_url": window.location.href,
        "event_id": fbEventId,
        "user_data": {
          "client_user_agent": window.navigator.userAgent,
          "em": sha256(window._keEmail || ""),
        },
        "custom_data": {
          "content_type": "product",
          "content_ids": [`${selectedStyle.product_id}`],
          "value": selectedVariant.price,
          "currency": "USD",
        },
      },
    ]

    // Facebook Conversions API
    if (window.hasOwnProperty("FB")) {
      window.FB.api(
        "/1633949316856164/events",
        "POST",
        {
          "access_token": "EAAL4uCupvgYBAAmNPb0A7diU6ugj3Q4XXfoaXJURVvEcMXmDkOqmZBvxKqMfqALwZBsuUZA2eRlDZBjZCO6GmP6bQjN7Q9KQRsINKoOsvs9mA8uj6ZAQ9UuzxN0CJXqx8YBNGXBE70nzzqVxGyU6DwA2YQxJyaY8T2nOueqGJsGZCEjECZCnaRftu8PugNuwWrAZD",
          "data": JSON.stringify(payload),
          // "test_event_code": "TEST13772"
        },
        function(response) {
          // Insert your code here
        }
      )
    }

    // Klaviyo
    if (window.hasOwnProperty("_learnq") && window.hasOwnProperty("klaviyoItem"))
      window._learnq.push(["track", "Add To Cart", window.klaviyoItem])

    // Pinterest
    if (window.hasOwnProperty("pintrk"))
      window.pintrk("track", "addtocart", {
        value: (parseInt(selectedVariant.price, 10) / 100).toFixed(2),
        order_quantity: 1,
        currency: "USD",
        product_id: selectedStyle.product_id,
      })

    // Custora
    if (window.hasOwnProperty("custoraTrack"))
      window.custoraTrack.addToCart(selectedVariant.variant_id, `$${selectedVariant.price}.00`, 1, `$${selectedVariant.compareAtPrice || "0"}.00`, selectedVariant.variant_id, title)

    // Heap
    if (window.hasOwnProperty("heap"))
      window.heap.track("Add to Cart")
  }

  useEffect(() => {
    window.criteo_q = window.criteo_q || []
    window.criteo_q.push(
      { event: "viewItem", ecpplugin: "shopify", product: product_id }
    )
  })

  const [detailsOpen, setDetailsOpen] = useState("Details")

  // Set BIS cookie if coming from BIS email
  if (parsedQuery.bis_id)
    cookie.set("bis_id", parsedQuery.bis_id, 30, location.hostname.replace("www.",""))

  const bannerTag = selectedStyle.tags.find(t => t.includes("BANNER:"))

  // FB ViewContent event, GA View Product Details event on style change
  useEffect(() => {
    // GA
    if (window.hasOwnProperty("gtag")) {
      window.gtag("event", "view_item", {
        "items": [{
          "id": `${selectedStyle.product_id}`,
          "name": title,
          "brand": "Tamara Mellon",
          "variant": `${selectedSku.color.toUpperCase()} / ${selectedVariant.size} / ${selectedStyle.heel_height}`,
          "quantity": 1,
          "price": `${selectedVariant.price}.00`,
          "category": productType,
        }],
      })
    }

    const fbEventId = Math.floor(Math.random() * 1000000) + ""

    if (window.hasOwnProperty("fbq"))
      window.fbq("track", "ViewContent",
        {
          content_type: "product",
          content_ids: [`${selectedStyle.product_id}`],
          value: selectedStyle.skus[0].variants[0].price,
          currency: "USD",
        },
        {
          eventID: fbEventId,
        },
      )

    const payload = [
      {
        "event_name": "ViewContent",
        "event_time": Math.floor(new Date().getTime() / 1000),
        "action_source": "website",
        "event_source_url": window.location.href,
        "event_id": fbEventId,
        "user_data": {
          "client_user_agent": window.navigator.userAgent,
          "em": sha256(window._keEmail || ""),
        },
        "custom_data": {
          "content_type": "product",
          "content_ids": [`${selectedStyle.product_id}`],
          "value": selectedStyle.skus[0].variants[0].price,
          "currency": "USD",
        },
      },
    ]

    // Facebook Conversions API
    if (window.hasOwnProperty("FB")) {
      window.FB.api(
        "/1633949316856164/events",
        "POST",
        {
          "access_token": "EAAL4uCupvgYBAAmNPb0A7diU6ugj3Q4XXfoaXJURVvEcMXmDkOqmZBvxKqMfqALwZBsuUZA2eRlDZBjZCO6GmP6bQjN7Q9KQRsINKoOsvs9mA8uj6ZAQ9UuzxN0CJXqx8YBNGXBE70nzzqVxGyU6DwA2YQxJyaY8T2nOueqGJsGZCEjECZCnaRftu8PugNuwWrAZD",
          "data": JSON.stringify(payload),
          // "test_event_code": "TEST13772"
        },
        function(response) {
          // Insert your code here
        }
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStyle])

  // Algolia view product event on sku change
  // useEffect(() => {
  //   // Algolia Insights
  //   aa("viewedObjectIDs", {
  //     userToken: appState.userToken,
  //     index: "tm_headless_search",
  //     eventName: "Product Viewed",
  //     objectIDs: [selectedSku.sku]
  //   })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSku])

  const [showPillowTopModal, setShowPillowTopModal] = useState(false)
  const [showCloudCushionModal, setShowCloudCushionModal] = useState(false)

  const closeModal = () => {
    setShowPillowTopModal(false)
    setShowCloudCushionModal(false)
  }

  // Initialize Affirm
  // useEffect(() => {
  //   if (window.affirm && window.affirm.ui)
  //     window.affirm.ui.ready(() => {
  //       window.affirm.ui.refresh()
  //     })
  // }, [])

  let categoryTag = selectedStyle.tags.find(t => t.includes("CAT:"))
  if (categoryTag === "CAT:HANDBAGS")
    categoryTag = "CAT:BAGS"

  const [showAddToCartFixed, setShowAddToCartFixed] = useState(false)

  // Show fixed ATC button on scroll
  // Set up info container to scroll into view
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
    return (
      window.removeEventListener("scroll", handleScroll)
    )
  })

  const infoEl = useRef(null)
  const lowestOffsetTop = (typeof window !== "undefined" && window.outerWidth >= 768) ? 50 : 0
  // const lowestOffsetTop = (typeof window !== "undefined" && window.outerWidth >= 768) ? 155 : 0 // with banner
  let lastScrollTop = 0
  let infoOffsetTop = lowestOffsetTop
  const [infoOffsetTopState, setInfoOffsetTopState] = useState(infoOffsetTop)

  const handleScroll = e => {
    // Fixed add to cart button
    if (window.outerWidth < 768)
      (window.scrollY > 850 && (selectedVariant.quantity > 0 || productType === "Gift Card")) ?
        setShowAddToCartFixed(true)
        :
        setShowAddToCartFixed(false)
    // Info scroll into view
    if (window.outerWidth >= 768 && infoEl && infoEl.current) {
      const containerDiff = window.innerHeight - infoEl.current.clientHeight - 20
      if (containerDiff - lowestOffsetTop < 0) { // Info container is taller than window
        const scrollDiff = lastScrollTop - window.scrollY
        if (scrollDiff > 0) { // Scrolled up
          if (infoOffsetTop + scrollDiff > lowestOffsetTop)
            infoOffsetTop = lowestOffsetTop
          else
            infoOffsetTop = infoOffsetTop + scrollDiff
        } else if (infoOffsetTop !== containerDiff) { // Scrolled down
          if (infoOffsetTop + scrollDiff < containerDiff)
            infoOffsetTop = containerDiff
          else
            infoOffsetTop = infoOffsetTop + scrollDiff
        }
        lastScrollTop = window.scrollY
        setInfoOffsetTopState(infoOffsetTop)
      }
    }
  }

  return(
    <>
      <SEO
        title={title}
        description={description}
        url={location.pathname}
      />

      <section className={`${(appState.zoomedImages) ? styles.mainWithZoomedImages : styles.main}`}>
        <div className={styles.images}>
          {title !== "Great Frontier - Leather" &&
            <PdpImages
              images={images}
              // videos={videos}
              selectedSku={selectedSku}
              title={title}
            />
          }

          {title === "Great Frontier - Leather" &&
            <PdpImagesVideoFirst
              images={images}
              // videos={videos}
              selectedSku={selectedSku}
              title={title}
            />
          }
        </div>

        <div
          className={`
            ${styles.info}
            ${(showSizeSelect) ? styles.infoSizesOpen : ""}
            ${(!categoryTag) ? styles.noBreadcrumbs : ""}
          `}
          key={key}
          ref={infoEl}
          style={{
            top: `${infoOffsetTopState}px`
          }}
        >

          {/*{categoryTag &&*/}
          {/*  <div className={styles.breadcrumbs}>*/}
          {/*    <Link to="/">Home</Link>*/}
          {/*    &nbsp;&rsaquo;&nbsp;*/}
          {/*    <Link*/}
          {/*      to={`/collections/${categoryTag.replace("CAT:", "").toLowerCase()}?origin=${selectedStyle.handle}`}>{toTitleCase(categoryTag.replace("CAT:", ""))}</Link>*/}
          {/*  </div>*/}
          {/*}*/}

          {bannerTag &&
            <h2 className={styles.banner}>{bannerTag.replace("BANNER:","")}</h2>
          }

          <h1 className={styles.title}>{title.split(" - ")[0]}</h1>

          {/* Don't show swatches if Gift Card or Sweet Revenge */}
          {productType !== "Gift Card" && !productStyle.handle.includes("sweet-revenge") &&
            <div className={styles.swatchContainer}>
              <h4 className={styles.heading}>{selectedSku.color} – {selectedSku.material}</h4>
              <Swatches
                skus={allSkus.sort((a, b) => [a.material, a.color] > [b.material, b.color] ? 1 : -1)}
                selectedSku={selectedSku}
                updateSelectedSku={updateSelectedSku}
                customStyles={styles}
              />
            </div>
          }

          {productStyle.handle.includes("sweet-revenge") &&
          <>
            <div className={styles.swatchContainer}>
              <h4 className={styles.heading}>{selectedSku.color} – {selectedSku.material}</h4>
              <Swatches
                skus={[selectedSku]}
                selectedSku={selectedSku}
                updateSelectedSku={updateSelectedSku}
                customStyles={styles}
              />
            </div>

            <div className={styles.legSizeContainer}>
              <select
                className={styles.amounts}
                value={selectedStyle.title.split(" - ")[1].replace("Stretch Nappa ","")}
                onChange={handleLegSizeChange}
                onBlur={handleLegSizeChange}
              >
                <option value="S">
                  Small
                </option>
                <option value="M">
                  Medium
                </option>
                <option value="L">
                  Large
                </option>
              </select>
            </div>
          </>
          }

          {quantityUpdated &&
          <div className={styles.sizeAndCtaContainer}>
            <div className={styles.sizeContainer}>
              {productType === "Footwear" ?
                <button
                  className={(sizeSelectError) ? styles.sizeSelectBtnError : styles.sizeSelectBtn}
                  onClick={e => setShowSizeSelect(!showSizeSelect)}
                >
                  {selectedSize ?
                    <>
                      {selectedVariant.size - 30} US / {selectedVariant.size} EU
                      {(Number(selectedVariant.quantity) <= 0) ?
                        <span>Notify me</span>
                        : (Number(selectedVariant.quantity) <= 2) ?
                          <span>{selectedVariant.quantity} left</span>
                          : ""
                      }
                    </>
                    :
                    <>Select Size</>
                  }

                </button>
                : productType === "Gift Card" ?
                  <div className={styles.amountsContainer}>
                    <select
                      className={styles.amounts}
                      value={selectedSku.color}
                      onChange={handleGiftCardAmountChange}
                      onBlur={handleGiftCardAmountChange}
                    >
                      {selectedStyle.skus
                        .sort((a, b) => Number(a.color) - Number(b.color))
                        .map((sku, i) => (
                          <option
                            key={i}
                            value={sku.color}
                          >
                            ${sku.color}
                          </option>
                        ))}
                    </select>
                  </div>
                  :
                  <div className={styles.sizeSelectBtn}>
                    One size
                  </div>
              }

              {showSizeSelect &&
              <>
                <div
                  className={`${overlayStyles.overlay} ${styles.overlay}`}
                  role="presentation"
                  onClick={e => setShowSizeSelect(false)}
                />
                <div className={styles.sizeSelect}>
                  <button
                    className={`${overlayStyles.closeBtn} ${styles.closeBtn}`}
                    onClick={e => setShowSizeSelect(false)}
                    title="Close"
                  >Close
                  </button>
                  <h4 className={styles.sizeSelectHeading}>Select Size</h4>
                  <ul className={styles.sizes}>
                    <li>
                      <button className={styles.sizesChatBtn} onClick={() => {
                        appState.set({chatOpen: true})
                        setShowSizeSelect(false)
                      }}>Chat with us for your perfect size</button>
                    </li>
                    {selectedSku.variants
                      .sort((a, b) => Number(a.size) - Number(b.size))
                      .map((variant, i) => (
                        <li key={i}>
                          <button
                            value={variant.size}
                            onClick={() => handleSizeClick(variant)}
                          >
                            {variant.size - 30} US / {variant.size} EU
                            <span>
                            {(Number(variant.quantity) <= 0) ?
                              `Notify me`
                              : (Number(variant.quantity) <= 2) ?
                                <em>{variant.quantity} left</em>
                                : ""
                            }
                            {selectedVariant.dateExpected && Number(variant.quantity) <= 2 && Number(variant.quantity) > 0 &&
                              ` - `
                            }
                            {selectedVariant.dateExpected && Number(variant.quantity) > 0 &&
                              `Preorder`
                            }
                            </span>
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </>
              }
            </div>

            {selectedStyle.fitNote?.note &&
              <p className={styles.fitNote}>{selectedStyle.fitNote.note}</p>
            }

            {!selectedSize && productType === "Footwear" ?
              <button
                type="button"
                className={styles.addToCartBtnInactive}
                onClick={() => setSizeSelectError("Please select a size first.")}
              >
                <span>Add to Cart</span>
                <span>
                  <Price
                    price={selectedVariant.price}
                    compareAtPrice={selectedVariant.compareAtPrice}
                    // intlPrices={selectedVariant.intlPrices}
                  />
                </span>
              </button>
            : selectedVariant.quantity <= 0 && productType !== "Gift Card" ?
              <div className={styles.addToCartBtnDisabled}>
                <span>Add to Cart</span>
                <span>
                  <Price
                    price={selectedVariant.price}
                    compareAtPrice={selectedVariant.compareAtPrice}
                    // intlPrices={selectedVariant.intlPrices}
                  />
                </span>
              </div>
            :
              <button
                type="button"
                className={styles.addToCartBtnActive}
                onClick={addToCart}
              >
                <span>
                {selectedVariant.dateExpected ?
                  `Preorder`
                  :
                  `Add to Cart`
                }
                </span>
                <span>
                  <Price
                    price={selectedVariant.price}
                    compareAtPrice={selectedVariant.compareAtPrice}
                    // intlPrices={selectedVariant.intlPrices}
                  />
                </span>
              </button>
            }
          </div>
          }

          {sizeSelectError &&
            <p className={styles.sizeSelectError}>{sizeSelectError}</p>
          }

          {productType !== "Gift Card" && quantity === 0 &&
            <p className={styles.oos}>Sold Out</p>
          }

          {/*{productType !== "Gift Card" && quantity > 0 &&*/}
          {/*  <p className={`${styles.affirm} affirm-as-low-as`} data-page-type="product" data-amount={selectedVariant.price * 100} data-affirm-color="black" />*/}
          {/*}*/}

          {(selectedSize || productType !== "Footwear") && selectedVariant.dateExpected && selectedVariant.quantity > 0 &&
            <p className={styles.dateExpected}>
              This item is estimated to ship by {new Date(selectedVariant.dateExpected).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}.
            </p>
          }

          {(selectedSize || productType !== "Footwear") && selectedVariant.quantity <= 0 && productType !== "Gift Card" &&
            <BIS
              variant={selectedVariant}
              style={selectedStyle}
              successProp={bisSuccess}
              setSuccessProp={setBisSuccess}
              customStyles={styles}
            />
          }

          {selectedStyle.handle === "pillow-talk-nappa-flats" &&
            <p className={styles.offer}>
              Our slippers do not come in half sizes, please size up if you are between sizes.
            </p>
          }

          {selectedVariant.compareAtPrice !== null && selectedStyle.tags.includes("EXCHANGE_ONLY") &&
          <p className={styles.offerRed}>
            This item is eligible for exchange only.
          </p>
          }

          {/*{selectedVariant.compareAtPrice !== null && selectedStyle.tags.includes("FINAL_SALE") &&*/}
          {selectedStyle.tags.includes("FINAL_SALE") &&
          <p className={styles.offerRed}>
            This item is final sale.
          </p>
          }

          {/*<p className={styles.offerRed}>*/}
          {/*  All discounted purchases are EXCHANGE ONLY, no refunds.*/}
          {/*</p>*/}

          {/*<p className={styles.offerShipping}>*/}
          {/*  Order with 2-day shipping by 12/21 at 12 p.m. EST to get it by 12/25.*/}
          {/*</p>*/}

          <PDPDetails
            heading="Details"
            detailsOpen={detailsOpen}
            setDetailsOpen={setDetailsOpen}
          >
            {selectedStyle.tags.includes("INSOLE:PILLOW") &&
            <button
              className={styles.insoleBtn}
              onClick={() => setShowPillowTopModal(true)}
            >
              PILLOW TOP INSOLE <span>Learn more</span>
            </button>
            }
            {selectedStyle.tags.includes("INSOLE:CLOUD") &&
            <button
              className={styles.insoleBtn}
              onClick={() => setShowCloudCushionModal(true)}
            >
              CLOUD CUSHION INSOLE <span>Learn more</span>
            </button>
            }
            {productType !== "Gift Card" &&
              <li>Color – {selectedSku.color}</li>
            }
            {selectedStyle.descriptionHtml.replace(/(<p>|<\/p>|<ul>|<\/ul>|<li>|<\/li>|<span>|<\/span>)/g,"").split("<br>").map((text, i) => (
              <li key={i}>{text}</li>
            ))}
            {/*{urgencyAmount !== null && urgencyAmount >= 10 &&*/}
            {/*  <li>*/}
            {/*    <p className={styles.urgency}>*/}
            {/*      {urgencyAmount} people are shopping this item*/}
            {/*    </p>*/}
            {/*  </li>*/}
            {/*}*/}
            <li className={styles.fitQuestions}>
              Fit questions?<br />
              <button onClick={() => appState.set({chatOpen: true})}>Connect with a liaison now</button>
            </li>
          </PDPDetails>

          {selectedSize && selectedVariant.bootFitGuide.ankle !== 0 &&
            <PDPDetails
              heading="Measurements"
              detailsOpen={detailsOpen}
              setDetailsOpen={setDetailsOpen}
            >
              {selectedVariant.bootFitGuide.shaft &&
                <li>Shaft Height – {convertMmToInches(selectedVariant.bootFitGuide.shaft)} inches</li>
              }
              {selectedVariant.bootFitGuide.calf &&
                <li>Calf Circumference – {convertMmToInches(selectedVariant.bootFitGuide.calf)} inches</li>
              }
              {selectedVariant.bootFitGuide.ankle &&
                <li>Ankle Circumference – {convertMmToInches(selectedVariant.bootFitGuide.ankle)} inches</li>
              }
              {selectedVariant.bootFitGuide.waist &&
                <li>Waist Circumference – {convertMmToInches(selectedVariant.bootFitGuide.waist)} inches</li>
              }
            </PDPDetails>
          }

          <PDPDetails
            heading="Shipping / Returns"
            detailsOpen={detailsOpen}
            setDetailsOpen={setDetailsOpen}
          >
            Complimentary shipping on all U.S. orders. Returns will be accepted for 14 days of receiving the item, and are subject to a handling fee.
          </PDPDetails>

          <FavoriteIcon
            sku={selectedSku.sku}
            style={selectedStyle}
            className={styles.favoriteBtn}
          />
        </div>
      </section>

      {showAddToCartFixed &&
        <div className={styles.addToCartContainer}>
          <button
            type="button"
            className={styles.addToCartBtnActive}
            onClick={addToCart}
          >
            {selectedVariant.dateExpected ?
              `Preorder`
              :
              `Add to Cart`
            }
          </button>
        </div>
      }

      {(showPillowTopModal || showCloudCushionModal) &&
        <div
          className={overlayStyles.overlay}
          role="presentation"
          onClick={closeModal}
        >
          <div className={`${overlayStyles.container} ${styles.modalContainer}`}>
            <button
              className={overlayStyles.closeBtn}
              onClick={closeModal}
              title="Close"
            >Close</button>
            {showPillowTopModal &&
              <>
                <Heading
                  tag="h4"
                  level="4"
                  className={styles.heading}
                >
                  First-Of-Its-Kind Luxury Cushioning, Pillow Top Insole
                </Heading>
                <ul>
                  <li>8mm of high-density Memory Foam</li>
                  <li>Dual layer cushioning for unparalleled comfort</li>
                  <li>Highly energy-absorbent and soft</li>
                  <li>Softens in response to body heat, molding to your foot</li>
                </ul>
                <video autoPlay loop muted playsInline className={styles.modalVideo}>
                  <source
                    src="https://cdn.shopify.com/s/files/1/1103/4464/files/Pillow_Top_720p.mp4?v=1615108252"
                    type="video/mp4" />
                </video>
              </>
            }
            {showCloudCushionModal &&
              <>
                <Heading
                  tag="h4"
                  level="4"
                  className={styles.heading}
                >
                  Luxury Cushioning, Cloud Cushion Insoles
                </Heading>
                <ul>
                  <li>6mm of ultra foam padding</li>
                  <li>Cushion where your foot needs it most</li>
                  <li>Relieves pressure points as you walk</li>
                  <li>Seamlessly integrated into shoe sole</li>
                </ul>
                <video autoPlay loop muted playsInline className={styles.modalVideo}>
                  <source
                    src="https://cdn.shopify.com/s/files/1/1103/4464/files/Cloud_Cushion_720p.mp4?v=1615108401"
                    type="video/mp4" />
                </video>
              </>
            }
          </div>
        </div>
      }

      {/*{productType !== "Gift Card" &&*/}
      {/*  <PdpYMAL*/}
      {/*    // products={recommendations}*/}
      {/*    objectID={selectedSku.sku}*/}
      {/*    title={selectedStyle.title}*/}
      {/*  />*/}
      {/*}*/}

      <section className={styles.quote}>
        <Heading
          tag="h2"
          level="1"
          className={styles.quoteHeading}
        >
          I co-founded <span>Jimmy Choo in 1996.</span>
        </Heading>
        <RichText
          json={contentfulTextBlock.description.json}
        />
      </section>

      {/*<RecentlyViewed*/}
      {/*  colorway={selectedSku.color}*/}
      {/*  shopifyProductId={product_id}*/}
      {/*/>*/}

      <Helmet>
        {/* Affirm */}
        <script async>{`
          var _affirm_config = {
            public_api_key: "XKI7FDVQ0VJSLQKZ",
            script: "https://cdn1.affirm.com/js/v2/affirm.js"
          };
          (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
        `}</script>

        {/* Microdata */}
        <script type="application/ld+json">{`
          [
            ${allSkus.filter(sku => sku.variants.length).map(sku => {
              return `{
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "${title} - ${sku.color}",
                "brand": "Tamara Mellon",
                "sku": "${sku.sku}",
                "url": "https://www.tamaramellon.com${productStyle.url}?color=${sku.color}",
                "description": "${description.replace(/"/g,"\\\"")}",
                "image": "${sku.images.pdp2[0]}",
                "offers": {
                  "@type": "Offer",
                  "priceCurrency": "USD",
                  "price": "${sku.variants[0].price}.00"
                }
              }`
            })}
          ]
        `}</script>
        
        {/* Klaviyo */}
        <script>{`
          window.klaviyoItem = {
            Name: "${title}",
            ProductID: "${product_id}",
            Categories: [${productStyle.collections.map(c => `"${c}"`)}],
            ImageURL: "${defaultSku.images.pdp2[0]}",
            URL: "https://www.tamaramellon.com${productStyle.url}?color=${defaultSku.color}",
            Brand: "Tamara Mellon",
            Price: "$${defaultVariant.price}.00",
            CompareAtPrice: "$${defaultVariant.compareAtPrice || "0"}.00"
          };
  
          window._learnq = window._learnq || [];
          
          window._learnq.push(["track", "Viewed Product", window.klaviyoItem]);
  
          window._learnq.push(["trackViewedItem", {
            Title: window.klaviyoItem.Name,
            ItemId: window.klaviyoItem.ProductID,
            Categories: window.klaviyoItem.Categories,
            ImageUrl: window.klaviyoItem.ImageURL,
            Url: window.klaviyoItem.URL,
            Metadata: {
              Brand: window.klaviyoItem.Brand,
              Price: window.klaviyoItem.Price,
              CompareAtPrice: window.klaviyoItem.CompareAtPrice
            }
          }]);
        `}</script>

        {/* Facebook */}
        <script>{`
          var pdpFbqTimer = setInterval(function() {
            if (window && window.hasOwnProperty('fbq')) {
              clearInterval(pdpFbqTimer);
              fbq('track', 'ViewContent', {
                content_type: "product",
                content_ids: "${product_id}"
              });
            }
          }, 50);
        `}</script>
      </Helmet>
    </>
  )
}

export default Product

export const pageQuery = graphql`
  query($id: String!) {
    productStyle(id: {eq: $id}) {
      ...ProductStyleFragment
#      relatedStyles {
#        ...ProductStyleFragment
#      }
    }
    contentfulTextBlock(componentName: {eq: "PDP Quote Module"}) {
      heading {
        ...HeadingFragment
      }
      description {
        json
      }
    }
  }
`

const PDPDetails = ({ heading, detailsOpen, setDetailsOpen, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  // Only one details drawer open at a time
  useEffect(() => {
    setIsOpen(heading === detailsOpen)
  }, [heading, detailsOpen])

  return (
    <div className={styles.detailsContainer}>
      <h4 className={(isOpen) ? styles.detailsHeadingOpen : styles.detailsHeading}>
        <button
          onClick={() => {
            if (heading === detailsOpen)
              setDetailsOpen(null)
            else
              setDetailsOpen(heading)
          }}
        >{heading}</button>
      </h4>
      <ul className={(isOpen) ? styles.detailsOpen : styles.detailsClosed}>
        {children}
      </ul>
    </div>
  )
}
