import React, { useEffect, useRef } from "react"
import styles from "./swatches.module.scss"

const Swatches = props => {
  const {
    skus,
    selectedSku,
    updateSelectedSku,
    customStyles,
    filteredColor,
  } = props

  const swatchRefs = useRef([])

  // Simulate click on swatch that matches filteredColor
  useEffect(() => {
    const swatchIndex = skus.filter(sku => !!sku.images.swatch).findIndex(sku => sku.colorFamily === filteredColor)
    if (swatchRefs.current[swatchIndex])
      swatchRefs.current[swatchIndex].click()
  })

  return(
    <ul className={`${styles.swatches} ${(customStyles) ? customStyles.swatches : ""}`}>
      {skus.filter(sku => !!sku.images.swatch)
        .map((sku, i) => (
        <li
          key={i}
          className={`${styles.swatchItem} ${(customStyles) ? customStyles.swatchItem : ""}`}
        >
          <button
            className={`${(sku.id === selectedSku.id) ? styles.selected : styles.button} ${(customStyles) ? customStyles.swatchButton : ""}`}
            onClick={() => updateSelectedSku(sku)}
            ref={el => (swatchRefs.current[i] = el)}
          >
            <img
              src={sku.images.swatch}
              alt={sku.color}
              className={styles.image}
            />
          </button>
        </li>
      ))}
    </ul>
  )
}

export default Swatches
