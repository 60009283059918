const convertLastViewedToRV = () => {
  const lastViewed = JSON.parse(localStorage.getItem("lastViewedProducts"))
  const recentlyViewed = (lastViewed || []).map(product => {
    return {
      name: product.title.split(" - ")[0],
      url: product.href,
      price: parseInt(product.price, 10) / 100,
      color: product.color,
      productId: product.productId,
      image: product.image,
      date: product.date,
    }
  })
  localStorage.removeItem("lastViewedProducts")
  localStorage.setItem("recentlyViewed", JSON.stringify(recentlyViewed))
}

export const getRecentlyViewed = () => {
  /* For testing */
  // return JSON.parse(`
  //   [
  //     {
  //       "image":"https://cdn.shopify.com/s/files/1/1103/4464/products/Rebel_75_Black_Patent_Pump_PDP_SUBCAT_1_SIDE_640x.progressive.jpg?v=1554175617",
  //       "title":"Rebel 75 - Patent",
  //       "price":"39500",
  //       "href":"/products/rebel-75-patent-1",
  //       "productId":10516135573,
  //       "date":1572480554991,
  //       "color":"black"
  //     },
  //     {
  //       "image":"//cdn.shopify.com/s/files/1/1103/4464/products/Fellini_55_Leopard_Haircalf_Pump_PDP_SUBCAT_1_Side.jpg?v=1565121548",
  //       "title":"Fellini - Haircalf",
  //       "price":"59500",
  //       "href":"/products/fellini-haircalf-pumps?color=leopard",
  //       "productId":2249342353504,
  //       "date":1572900999359,
  //       "color":"leopard"
  //     }
  //   ]
  // `)
  if (typeof window === "undefined")
    return []
  if (!window.localStorage)
    return []
  if (localStorage.getItem("lastViewedProducts"))
    convertLastViewedToRV()
  try {
    return JSON.parse(localStorage.getItem("recentlyViewed"))
  } catch {
    return []
  }
}
