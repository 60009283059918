import React, { useState, useEffect } from "react"
import Swiper from "react-id-swiper"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

import Image from "./Image"

import styles from "./pdpimages.module.scss"
import overlayStyles from "./overlay.module.scss"

const PdpImagesVideoFirst = ({ images, videos, selectedSku, title }) => {

  const [zoomedImageSwiper, getZoomedImageSwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  // const [mobileImageSwiper, getMobileImageSwiper] = useState(null)
  // const [mobileZoomedImageSwiper, getMobileZoomedImageSwiper] = useState(null)

  const imageSwiperParams = {
    WrapperEl: "ol",
    threshold: 10,
    watchOverflow: true,
    preloadImage: true,
    updateOnImagesReady: true,
  }
  const zoomedImageSwiperParams = {
    ...imageSwiperParams,
    getSwiper: getZoomedImageSwiper,
    containerClass: `${styles.zoomSwiperContainer}`,
    slideClass: `${styles.swiperSlide}`,
    slidesPerView: "auto",
    loop: false,
    direction: "vertical",
    mousewheel: true,
    freeMode: true,
  }
  const thumbnailSwiperParams = {
    ...imageSwiperParams,
    getSwiper: getThumbnailSwiper,
    containerClass: `${styles.thumbSwiperContainer}`,
    wrapperClass: `${styles.swiperWrapper}`,
    slideClass: `${styles.swiperSlide}`,
    slideActiveClass: `${styles.swiperSlideActive}`,
    centeredSlides: true,
    spaceBetween: 8,
    slidesPerView: "auto",
    touchRatio: 0.2,
    slideToClickedSlide: true,
    direction: "vertical",
  }
  const mobileImageSwiperParams = {
    ...imageSwiperParams,
    // getSwiper: getMobileImageSwiper,
    containerClass: `${styles.mobileSwiperContainer}`,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    rebuildOnUpdate: true,
  }
  const mobileZoomedImageSwiperParams = {
    ...imageSwiperParams,
    // getSwiper: getMobileZoomedImageSwiper,
    containerClass: `${styles.mobileZoomSwiperContainer}`,
    slideClass: `${styles.swiperSlide}`,
    slideActiveClass: `${styles.swiperSlideActive}`,
    slidesPerView: 1,
    loop: true,
    allowTouchMove: false,
    rebuildOnUpdate: true,
    navigation: {
      prevEl: '.swiper-navigation-prev',
      nextEl: '.swiper-navigation-next',
    },
    renderPrevButton: () => <button className={`${styles.swiperButtonPrev} swiper-navigation-prev`} aria-label="Previous" />,
    renderNextButton: () => <button className={`${styles.swiperButtonNext} swiper-navigation-next`} aria-label="Next" />,
    // zoom: {
    //   containerClass: `${styles.swiperZoomContainer} swiper-zoom-container`,
    // },
  }

  const [showZoomedImages, setShowZoomedImages] = useState(false)
  const [slideTo, setSlideTo] = useState(0)

  // Connect thumbnail and zoomed image swipers, update them on show, and slide to active slide
  useEffect(() => {
    if (showZoomedImages && zoomedImageSwiper && thumbnailSwiper) {
      zoomedImageSwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = zoomedImageSwiper
      zoomedImageSwiper.update()
      thumbnailSwiper.update()
      zoomedImageSwiper.slideTo(slideTo, 0)
      thumbnailSwiper.slideTo(slideTo, 0)
    }
  }, [zoomedImageSwiper, thumbnailSwiper, showZoomedImages, slideTo])

  // useEffect(() => {
  //   if (typeof window !== "undefined" && window.outerWidth < 768 && mobileImageSwiper !== null) {
  //     mobileImageSwiper.update()
  //     mobileImageSwiper.slideTo(1, 0)
  //   }
  // }, [selectedSku, mobileImageSwiper])

  // useEffect(() => {
  //   if (showZoomedImages && mobileZoomedImageSwiper) {
  //     mobileZoomedImageSwiper.update()
  //   }
  // }, [mobileZoomedImageSwiper, showZoomedImages])

  // When mobile zoom is opened, slide to active slide
  useEffect(() => {
    if (showZoomedImages && typeof window !== "undefined" && window.outerWidth < 768)
      document.querySelector(`.${styles.mobileZoomSwiperContainer.split(" ")[0]}`).swiper.slideToLoop(slideTo, 0)
  }, [showZoomedImages, slideTo])

  const [activeImage, setActiveImage] = useState(0)

  // Set observers for desktop images to highlight pagination
  useEffect(() => {
    if (typeof window !== "undefined" && window.outerWidth >= 768) {

      const callback = (entries, observer) => {
        entries.forEach((entry, i) => {
          if (entry.isIntersecting)
            setActiveImage(Number(entry.target.dataset.image))
        })
      }

      const observer = new IntersectionObserver(callback, {
        threshold: .5,
      })

      document.querySelectorAll(`.${styles.imageContainer} li`).forEach(target => {
        observer.observe(target)
      })

      setTimeout(() => {
        setActiveImage(0)
      }, 500)
    }
  }, [images, selectedSku])

  return(
    <>
      {typeof window !== "undefined" && window.outerWidth >= 768 &&
      <>
        <ul className={styles.imageContainer}>
          {videos.map((video, i) => (
            <li key={images.length+i} data-image={images.length+i}>
              <video autoPlay loop muted playsInline className={styles.video} key={video}>
                <source
                  src={video}
                  type="video/mp4" />
              </video>
            </li>
          ))}
          {images.sort().map((image, i) => (
            <li key={i} data-image={i}>
              <div
                role="presentation"
                onClick={() => {
                  setShowZoomedImages(true)
                  setSlideTo(i)
                }}
              >
                <Image
                  image={image}
                  altText={`${title} - ${selectedSku.color}`}
                  options={{
                    origin: "shopify",
                    width: "1500",
                  }}
                  className={styles.mainImage}
                />
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.imagePaginationContainer}>
          <ul className={styles.imagePagination}>
            {videos.map((video, i) => (
              <li key={images.length+i} className={(images.length+i === activeImage) ? styles.imagePaginationActive : ""} />
            ))}
            {images.sort().map((image, i) => (
              <li key={i} className={(i === activeImage) ? styles.imagePaginationActive : ""} />
            ))}
          </ul>
        </div>
        <div className={`${(selectedSku.images.pdp2.length) ? styles.zoomContainer3x4 : styles.zoomContainer1x1} ${(showZoomedImages) ? styles.zoomContainerOpen : ""}`}>
          <button
            className={`${overlayStyles.closeBtn} ${styles.closeBtn}`}
            onClick={() => setShowZoomedImages(false)}
            aria-label="Close"
          />
          <Swiper {...zoomedImageSwiperParams}>
            {images.sort().map((image, i) => (
              <li key={i}>
                <div
                  role="presentation"
                  onClick={() => setShowZoomedImages(false)}
                >
                  <Image
                    image={image}
                    altText={`${title} - ${selectedSku.color}`}
                    options={{
                      origin: "shopify",
                      width: "3000",
                    }}
                    className={styles.mainImage}
                  />
                </div>
              </li>
            ))}
          </Swiper>
          <Swiper {...thumbnailSwiperParams}>
            {images.sort().map((image, i) => (
              <li key={i}>
                <Image
                  image={image}
                  altText={`${title} - ${selectedSku.color}`}
                  options={{
                    origin: "shopify",
                    width: "120",
                  }}
                  className={styles.thumbImage}
                />
              </li>
            ))}
          </Swiper>
        </div>
      </>
      }
      {typeof window !== "undefined" && window.outerWidth < 768 &&
      <>
        <Swiper {...mobileImageSwiperParams}>
          {videos.map((video, i) => (
            <li key={`${i}vid`}>
              <video autoPlay loop muted playsInline className={styles.video} key={video}>
                <source
                  src={video}
                  type="video/mp4" />
              </video>
            </li>
          ))}
          {images.sort().map((image, i) => (
            <li key={i}>
              <div
                role="presentation"
                onClick={() => {
                  setShowZoomedImages(true)
                  // mobileZoomedImageSwiper.slideToLoop(i, 0)
                  setSlideTo(i)
                }}
              >
                <Image
                  image={image}
                  altText={`${title} - ${selectedSku.color}`}
                  options={{
                    origin: "shopify",
                    width: "850",
                  }}
                  className={styles.mainImage}
                />
              </div>
            </li>
          ))}
        </Swiper>
        <div className={`${styles.zoomContainer} ${(showZoomedImages) ? styles.zoomContainerOpen : ""}`}>
          <button
            className={`${overlayStyles.closeBtn} ${styles.closeBtn}`}
            onClick={() => setShowZoomedImages(false)}
            aria-label="Close"
          />
          <Swiper {...mobileZoomedImageSwiperParams}>
            {images.sort().map((image, i) => (
              <li key={i}>
                {/* Docs: https://github.com/prc5/react-zoom-pan-pinch */}
                <TransformWrapper>
                  <TransformComponent>
                    <Image
                      image={image}
                      altText={`${title} - ${selectedSku.color}`}
                      options={{
                        origin: "shopify",
                        width: "3000",
                      }}
                      className={styles.mainImage}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </li>
            ))}
          </Swiper>
          {/*<button*/}
          {/*  className={`${styles.swiperButtonPrev} swiper-navigation-prev`}*/}
          {/*  onClick={() => mobileZoomedImageSwiper.slidePrev()}*/}
          {/*/>*/}
          {/*<button*/}
          {/*  className={`${styles.swiperButtonNext} swiper-navigation-next`}*/}
          {/*  onClick={() => mobileZoomedImageSwiper.slideNext()}*/}
          {/*/>*/}
        </div>
      </>
      }
    </>
  )
}

export default PdpImagesVideoFirst
