import React, { useState, useEffect, useContext, useRef } from "react"
import { AppContext } from "../components/Context"

import favorites from "../helpers/favorites"

import styles from "./favoriteicon.module.scss"
import { trackEvent } from "../helpers"

const FavoriteIcon = ({ sku, style, className }) => {
  const appState = useContext(AppContext)
  const [isFavorite, setIsFavorite] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const buttonEl = useRef(null)

  const handleClick = () => {
    (!isFavorite) ? favorites.addItem(sku) : favorites.removeItem(sku)
    setIsFavorite(!isFavorite)
    setIsHovered(true)
    favorites.getListSkus()
      .then(skus => {
        appState.set({
          favoritesSkus: skus,
        })
      })
    analytics((isFavorite) ? "Added" : "Removed")
  }
  
  const analytics = event => {
    // GA
    trackEvent("Wishlist", event, sku)
    
    // Facebook
    if (window.hasOwnProperty("fbq"))
      window.fbq("trackCustom", `Wishlist${event}`, {
        content_ids: [sku],
      })

    // Klaviyo
    if (window.hasOwnProperty("_learnq")) {
      const skuData = style.skus.find(s => s.sku === sku);
      window._learnq.push(["track", `Wishlist ${event}`, {
        VariantSku: sku,
        ImageURL: skuData.images.pdp[0],
        Price: `$${skuData.variants[0].price}.00`,
        Name: style.title,
        URL: window.location.href,
        Color: skuData.color,
        ProductID: style.product_id,
      }])
    }
    
    // Data Layer
    window.dataLayer.push({"event": `Wishlist${event}`});
  }

  useEffect(() => {
    setIsFavorite(appState.favoritesSkus.includes(sku))
  }, [appState, setIsFavorite, sku])

  const handleMouseLeave = e => {
    setIsHovered(false)
  }

  useEffect(() => {
    buttonEl.current.addEventListener("mouseleave", handleMouseLeave, true)
    return (
      buttonEl.current.removeEventListener("mouseleave", handleMouseLeave)
    )
  }, [buttonEl])

  return(
    <button
      className={`${(isFavorite) ? styles.added : styles.notAdded} ${(isHovered) ? styles.hovered : ""} ${className || ""}`}
      onClick={handleClick}
      ref={buttonEl}
      aria-label={(isFavorite) ? "Remove Favorite" : "Add Favorite"}
    />
  )
}

export default FavoriteIcon
