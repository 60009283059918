import React from "react"

import ProductSwiper from "./ProductSwiper"

import styles from "./recentlyviewed.module.scss"

import { getRecentlyViewed } from "../helpers/recentlyViewed"


export const saveProductToRV = style => {
  if (typeof window === "undefined")
    return
  if (!window.localStorage)
    return

  let products = getRecentlyViewed()
  products = (products || []).filter(p => p.productId !== style.product_id).slice(0, 20)

  const product = {
    name: style.title.split(" - ")[0],
    productId: style.product_id,
    url: style.url.replace("https://www.tamaramellon.com", ""),
    price: style.skus[0].variants[0].price,
    color: style.skus[0].color,
    image: style.skus[0].images.plp2[0],
    date: Date.now(),
  }

  products.unshift(product)

  localStorage.setItem('recentlyViewed', JSON.stringify(products));
}

const RecentlyViewed = ({ colorway, shopifyProductId }) => {

  let products = getRecentlyViewed()
  products = (products || []).filter(p => p.productId !== colorway.shopifyProductId)

  const rvData = {
    componentName: "Recently Viewed",
    slug: "recently-viewed",
    heading: {
      heading: "Recently Viewed",
      textSize: "heading-2",
      textAlignment: "center",
    },
    components: products,
    initCarousel: true,
    navigation: true,
    pagination: false,
    layout: "Product Grid",
    hidePricing: true,
    internal: {
      type: "ContentfulMultipleComponents",
    },
    id: "",
  }

  const params = {
    slidesPerView: 1.8,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    // slidesOffsetBefore: products.length > 1 ? 32 : 0,
    // slidesOffsetAfter: products.length > 1 ? 32 : 0,
    spaceBetween: 32,
    watchOverflow: true,
    centerInsufficientSlides: true,
    breakpoints: {
      // when window width is >= 768px
      768: {
        slidesPerView: 4,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
        // slidesOffsetBefore: products.length > 3 ? 32 : 0,
        // slidesOffsetAfter: products.length > 3 ? 32 : 0,
      },
    },
    rebuildOnUpdate: true,
  }

  return (
    <>
      {products.length > 0 &&
        <ProductSwiper
          data={rvData}
          customStyles={styles}
          customParams={params}
          origin="rv"
        />
      }
    </>
  )
}

export default RecentlyViewed
