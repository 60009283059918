import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Swiper from 'react-id-swiper';

import Heading from "./Heading"
import Image from "./Image"
import { getDefaultParams } from "./Swiper"

// import swiperStyles from "./swiper.module.scss"
import sectionStyles from "./productswiper.module.scss"

import { toTitleCase } from "../helpers"


const ProductSwiper = props => {

  const {
    data,
    customStyles,
    customParams,
    origin,
  } = props

  const {
    heading,
    components,
    hidePricing,
    navigation,
  } = data

  const [productSwiper, getProductSwiper] = useState(null)

  const getSwiperParams = (slideCount, styles, customParams) => {
    return {
      ...getDefaultParams(data, styles),
      ...{
        getSwiper: getProductSwiper,
        spaceBetween: 20,
        slidesPerView: 1.6,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        preloadImage: true,
        speed: 300,
        breakpoints: {
          // when window width is >= 768px
          768: {
            slidesPerView: 3.6,
            slidesOffsetBefore: 40,
            slidesOffsetAfter: 40,
          },
          // when window width is >= 1024px
          1024: {
            spaceBetween: 12,
            slidesPerView: 5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            // allowTouchMove: false,
          },
        },
      },
      ...customParams,
    }
  }

  const handleClickPrev = () => {
    productSwiper.destroyed ?
      document.querySelector(`.${customStyles.swiperContainer}`).swiper.slidePrev()
    :
      productSwiper.slidePrev()
  }

  const handleClickNext = () => {
    productSwiper.destroyed ?
      document.querySelector(`.${customStyles.swiperContainer}`).swiper.slideNext()
    :
      productSwiper.slideNext()
  }

  const headingLevel = heading ? (heading.textSize || "2").replace("heading-","") : null
  const headingAlign = heading ? toTitleCase(heading.textAlignment || "left") : null

  const slideCount = components.length

  const styles = {...sectionStyles, ...customStyles}

  const params = getSwiperParams(slideCount, styles, customParams)

  return (
    <section className={styles.productSwiper}>
      <div className={styles.sectionWrapper}>
        {heading &&
          <Heading
            tag="h2"
            level={headingLevel}
            alignment={headingAlign}
            className={styles.heading}
          >
            {heading.heading}
          </Heading>
        }
        <Swiper {...params}>
          {components.map((product, i) =>
            <li
              key={i}
              className={styles.swiperSlide}
            >
              {product.url &&
                <Link
                  to={`${product.url}?origin=${origin || data.componentName.toLowerCase().replace(/\s/g, "-")}-${i+1}`}
                  className={styles.imageLink}
                >
                  {(product.subcatSide || product.image) &&
                    <Image
                      image={product.subcatSide || product.image}
                      altText={product.name}
                      className={styles.productImage}
                    />
                  }
                  {product.badge &&
                    <h5
                      className={styles.badge}
                    >
                      {product.badge}
                    </h5>
                  }
                </Link>
              }
              {product.name &&
                <Heading
                  tag="h4"
                  level="5"
                  className={styles.productTitle}
                >
                  {product.url &&
                    <Link
                      to={product.url}
                    >
                      {product.name}
                    </Link>
                  }
                </Heading>
              }
              {product.price && !hidePricing &&
                <p
                  className={styles.productPrice}
                >
                  ${parseInt(product.price, 10).toLocaleString()}
                </p>
              }
            </li>
          )}
        </Swiper>
        {navigation &&
          <>
            <button
              className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
              onClick={handleClickPrev}
              aria-label="Previous"
            />
            <button
              className={`${styles.swiperButtonNext} swiper-navigation-next`}
              onClick={handleClickNext}
              aria-label="Next"
            />
          </>
        }
      </div>
    </section>
  )
}

ProductSwiper.propTypes = {
  data: PropTypes.object.isRequired,
  customStyles: PropTypes.object,
}

export default ProductSwiper

export const query = graphql`
  fragment ProductFragment on ContentfulProductTile {
    name
    id
    url
    price
    badge
    color
    productId
    subcatSide {
      fluid(maxWidth: 850, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
